import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
// import VueMask from 'v-mask'
// import * as VueGoogleMaps from 'vue2-google-maps'
// import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";
// import interceptorsSetup from './interceptors' // importing the helper

// interceptorsSetup()
Vue.config.productionTip = false
// Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
// Vue.use(VueMask)
// Vue.use(require('vue-chartist'))
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
//     libraries: 'places',
//   },
//   installComponents: true
// })
// Vue.component('apexchart', VueApexCharts)

Vue.mixin({
  data: function() {
    return {
      get currentYear() {
        return new Date().getFullYear();
      }
    }
  }
})

Vue.filter('currencyFormat', function (value) {
  return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(value)
})

Vue.filter('dateFormat', function (date) {
  const d = new Date(date)
  var options = { year: 'numeric', month: 'long', day: 'numeric' }
  return d.toLocaleDateString('en-NG', options)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
