import axios from "axios";
import { authHeader } from './auth-header';

// const apiUrl = 'http://localhost:8000/api/v1';
const apiUrl = "https://affiliate-api.cadlands.com/api/v1";

export function http() {
    return axios.create({
        baseURL: apiUrl,
        headers: authHeader()
    });
}

export function httpFile() {
    var headers = authHeader();
    headers['Content-Type'] = 'multipart/form-data'
    return axios.create({
        baseURL: apiUrl,
        headers,
        // headers: {
        //     "Content-Type": "multipart/form-data",
        // }
    });
}
