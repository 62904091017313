import { userService } from '@/services/user.service';
// import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    setUser ({ dispatch, commit }, user) {
        commit('setUser', user);
    },
    updatePhoto ({ commit }, newPhoto) {
        commit('updatePhoto', newPhoto);
    },

    login({ commit }, user) {
        commit('loginSuccess', user);
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerSuccess', user);
        dispatch('notification/success', 'Registration successful', { root: true });
    },
    
};

export const mutations = {
    setUser(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    updatePhoto(state, newPhoto) {
        state.user.photo = newPhoto;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

