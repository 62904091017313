// import { authHeader } from "./auth-header";
import { http, httpFile } from "./http.service";
// import store from '@/store';

export const userService = {
  login,
  logout,
  register,
  validatePayment,
  isLoggedIn,
  getProfile,
  getReferrals,
  getAffiliates,
  activateAffiliate,
  updateProfile,
  changePhoto,
  resendToken,
  verifyAccount,
  resetPassword
  // getAll,
};

function login(user) {
  return http()
    .post("/auth/login", user)
    .then((response) => {
      // console.log(response);
      if (response){
        if (response.status === 200) {
          setToken(response.data);
        }
        return response.data;
      }
    });
}

function setToken(payload) {
    console.log(`token: ${payload.data.access_token}`);
    localStorage.setItem('user', JSON.stringify(payload.data.access_token));
    // store.dispatch('authenticate', payload.data.user);
}

function isLoggedIn() {
  const token = localStorage.getItem('user');
  return token != null;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function register(user) {
  return http().post("/auth/signup", user);
}

function getProfile() {
  return http().get("/user");
}

function getReferrals() {
  return http().get("/referrals");
}

function getAffiliates() {
  return http().get("/affiliates");
}

function activateAffiliate (data, userId) {
  return http().post('/affiliates/' + userId + '/activate', data);
}

function updateProfile(profile) {
  return http().put("/users", profile)
}

function changePhoto(data) {
  return httpFile().post("/changePhoto", data)
}

function validatePayment(payment) {
  return http().post("/validatepayment", payment);
}

function resendToken(user) {
  return http().post("/auth/token/send", user);
}

function verifyAccount(user) {
  return http().post("/auth/email/verification", user);
}

function resetPassword(user) {
  return http().post("/auth/resetpassword", user);
}

// function getAll() {
//   const requestOptions = {
//     method: "GET",
//     headers: authHeader(),
//   };
//   return fetch(`/users`, requestOptions).then(handleResponse);
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         // auto logout if 401 response returned from api
//         logout();
//         location.reload(true);
//       }
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         // auto logout if 401 response returned from api
//         logout();
//         location.reload(true);
//       }
//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }
