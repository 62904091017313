<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { userService } from "@/services/user.service";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  beforeCreate: async function () {
    try {
      // console.log('fetching..');
      if (this.$store.state.auth.status.loggedIn) {
        const response = await userService.getProfile();
        this.$store.dispatch('auth/setUser', response.data.data.user);
        
        // console.log('route meta')
        // console.log(this.$route.meta)
        if (this.$route.meta.role) {
          // check if this user has this role
          if (this.$store.state.auth.user.iam != this.$route.meta.role) {
            this.$router.push('/pages/404')
          }
        }

        // check if this user has not paid
        if (this.$store.state.auth.user.payment_date == null) {
          this.$router.push('/payment');
        }
      }
    } catch (e) {
      console.log(e);
      this.$router.push('/logout');
    }
  },
};
</script>