export function authHeader() {
    // return authorization header with jwt token
    let userToken = JSON.parse(localStorage.getItem('user'));
    // if (user && user.token) {
    if (userToken) {
        return { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + userToken };
    } else {
        return {
            'Content-Type': 'application/json',
        };
    }
}